import React from 'react';

export default function LoginGovSVG() {
  return (
    <svg
      role="img"
      viewBox="0 0 114 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Login.gov</title>
      <g clipPath="url(#a)" fill="#fff">
        <path d="M10.388 0h-8.7C.756 0 0 .739 0 1.65v8.086a.974.974 0 0 0 .102.443c.366.714 1.827 3.046 5.934 4.821 4.129-1.786 5.572-4.1 5.938-4.821a.975.975 0 0 0 .102-.443V1.65c0-.438-.178-.857-.495-1.167A1.708 1.708 0 0 0 10.388 0ZM7.673 9.643a9.78 9.78 0 0 1-3.248 0l.562-3.15a1.641 1.641 0 0 1-.665-1.31A1.683 1.683 0 0 1 5.646 3.54a1.726 1.726 0 0 1 1.931.899 1.65 1.65 0 0 1-.47 2.04c.2 1.042.376 2.092.566 3.164Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.822 2.218H17.39V12.75h7.052v-2.075h-4.619V2.218Z"
        />
        <path d="M31.96 2.143c-3.288 0-5.788 2.271-5.788 5.357 0 3.086 2.492 5.357 5.788 5.357 3.296 0 5.788-2.332 5.788-5.357 0-3.025-2.492-5.357-5.788-5.357Zm.03 8.636c-1.842-.014-3.328-1.479-3.326-3.279a3.202 3.202 0 0 1 .964-2.313 3.353 3.353 0 0 1 2.361-.955 3.326 3.326 0 0 1 2.331.968c.614.614.95 1.442.936 2.3.018.86-.318 1.69-.932 2.307a3.326 3.326 0 0 1-2.335.972Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.943 4.204A4.568 4.568 0 0 1 48.9 5.379l1.37-1.7a6.873 6.873 0 0 0-4.326-1.536c-3.357 0-5.882 2.303-5.882 5.357 0 3.054 2.48 5.357 5.743 5.357a8.095 8.095 0 0 0 4.385-1.371V6.957h-4.005v1.86h1.882v1.39c-.65.344-1.37.54-2.108.572a3.384 3.384 0 0 1-3.007-1.612 3.226 3.226 0 0 1 0-3.352 3.384 3.384 0 0 1 3.007-1.611h-.015Z"
        />
        <path d="M56.12 2.218h-2.434V12.75h2.433V2.218Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m67.311 8.99-5.236-6.772h-2.218V12.75h2.324V5.996l5.222 6.754h2.214V2.218H67.31v6.771ZM74.206 10.093a1.353 1.353 0 0 0-.993.395c-.26.26-.397.616-.377.98-.026.494.229.962.663 1.217.434.255.977.255 1.41 0 .435-.255.69-.723.663-1.217a1.28 1.28 0 0 0-.372-.983 1.342 1.342 0 0 0-.994-.392ZM83.34 4.204a4.568 4.568 0 0 1 2.957 1.175l1.37-1.7a6.873 6.873 0 0 0-4.326-1.536c-3.358 0-5.88 2.303-5.88 5.357 0 3.054 2.481 5.357 5.744 5.357a8.096 8.096 0 0 0 4.385-1.371V6.957h-4.008v1.86h1.882v1.39c-.65.344-1.37.54-2.109.572a3.384 3.384 0 0 1-3.006-1.612 3.225 3.225 0 0 1 0-3.352 3.384 3.384 0 0 1 3.006-1.611h-.014Z"
        />
        <path d="M96.06 2.143c-3.289 0-5.788 2.271-5.788 5.357 0 3.086 2.495 5.357 5.787 5.357 3.293 0 5.792-2.332 5.792-5.357 0-3.025-2.496-5.357-5.791-5.357Zm.036 8.636c-1.847-.014-3.335-1.487-3.325-3.293a3.202 3.202 0 0 1 .964-2.313 3.353 3.353 0 0 1 2.361-.955c.88.007 1.719.359 2.331.975.613.617.947 1.448.928 2.307a3.175 3.175 0 0 1-.929 2.304 3.326 3.326 0 0 1-2.33.975Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m111.49 2.218-2.956 7.946-2.942-7.946h-2.616l4.235 10.532h2.51L114 2.218h-2.51Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h114v15H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
